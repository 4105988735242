/* OurHomes.css */
.our-homes-page {
    overflow-x: hidden;
    margin-top: 80px;
  }
  
  /* Hero Section */
  .homes-hero {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .homes-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }
  
  .add-review-btn {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
  }
  
  .add-review-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* Reviews Section */
  .reviews-section {
    padding: 100px 0;
    background-color: #f5f5f5;
  }
  
  .reviews-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 30px;
    margin-top: 20px;
  }
  
  .review-card-wrapper {
    height: 100%;
  }
  
  .review-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
    height: 100%;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .review-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
  
  .review-image-container {
    position: relative;
    height: 220px;
    overflow: hidden;
  }
  
  .review-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
  }
  
  .review-card:hover .review-image {
    transform: scale(1.1);
  }
  
  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 0, 0, 0.7);
    border: none;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .review-card:hover .delete-button {
    opacity: 1;
  }
  
  .review-rating {
    margin: 15px 0;
    display: flex;
    gap: 5px;
  }
  
  .star-icon {
    color: #ccc;
    font-size: 1.2rem;
  }
  
  .star-icon.filled {
    color: #FFD700;
  }
  
  .review-content {
    position: relative;
    padding: 0 0 0 30px;
    margin-bottom: 20px;
    flex-grow: 1;
  }
  
  .quote-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(233, 64, 87, 0.2);
    font-size: 1.5rem;
  }
  
  .reviewer-info {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .user-icon {
    color: #E94057;
    margin-right: 8px;
  }
  
  .reviewer-name {
    font-weight: 600;
    color: #333;
  }
  
  /* CTA Section */
  .cta-section {
    padding: 80px 0;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 20px;
  }
  
  .separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    margin: 0 auto 30px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto 30px;
  }
  
  .cta-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
    font-size: 1.1rem;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Review Modal */
  .review-modal .modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .review-modal .modal-header {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;
  }
  
  .review-modal .modal-header .close {
    color: white;
  }
  
  .review-modal .modal-title {
    font-weight: 700;
  }
  
  .rating-selector {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
  
  .star {
    cursor: pointer;
    font-size: 1.5rem;
    color: #ccc;
    transition: all 0.2s ease;
  }
  
  .star:hover,
  .star.selected {
    color: #FFD700;
    transform: scale(1.2);
  }
  
  .upload-area {
    position: relative;
    margin-bottom: 15px;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-label {
    width: 100%;
    padding: 10px 15px;
    background-color: #f8f9fa;
    border: 2px dashed #ddd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .file-input:hover + .file-label,
  .file-input:focus + .file-label {
    border-color: #E94057;
    background-color: rgba(233, 64, 87, 0.05);
  }
  
  .image-preview-container {
    margin-top: 15px;
    text-align: center;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 200px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .submit-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover:not(:disabled) {
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
    box-shadow: 0 5px 15px rgba(233, 64, 87, 0.3);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Delete Modal */
  .delete-modal .modal-content {
    border-radius: 15px;
    border: none;
  }
  
  .delete-preview-image {
    max-width: 100%;
    max-height: 200px;
    border-radius: 8px;
  }
  
  /* Progress bar */
  .progress {
    height: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
  }
  
  .progress-bar {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border-radius: 5px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .section-title {
      font-size: 2.2rem;
    }
    
    .reviews-container {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
    }
    
    .section-title {
      font-size: 1.8rem;
    }
    
    .reviews-container {
      grid-template-columns: 1fr;
    }
    
    .rating-selector {
      justify-content: center;
    }
  }