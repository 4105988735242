/* Offerings.css */
.offerings-page {
    overflow-x: hidden;
    margin-top: 80px;
  }
  
  /* Hero Section */
  .offerings-hero {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .offerings-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* One Stop Shop section */
  .one-stop-section {
    padding: 100px 0 80px;
    background-color: #f5f5f5;
  }
  
  .section-title-wrapper {
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    margin: 0 auto 30px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Features */
  .features-container {
    margin-top: 30px;
  }
  
  .feature-item {
    background: white;
    border-radius: 15px;
    padding: 30px 20px;
    text-align: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    height: 100%;
    transition: all 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon-wrapper {
    width: 70px;
    height: 70px;
    margin: 0 auto 20px;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.1) 0%, rgba(233, 64, 87, 0.1) 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .feature-item:hover .feature-icon-wrapper {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 100%);
  }
  
  .feature-icon {
    font-size: 2rem;
    color: #E94057;
    transition: all 0.3s ease;
  }
  
  .feature-item:hover .feature-icon {
    color: white;
    transform: scale(1.1);
  }
  
  .feature-item h3 {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
  }
  
  .feature-item p {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Packages Section */
  .packages-section {
    padding: 100px 0;
    background-color: white;
    position: relative;
    overflow: hidden;
  }
  
  .diagonal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.03) 0%, rgba(233, 64, 87, 0.03) 50%, rgba(242, 113, 33, 0.03) 100%);
    transform: skewY(-5deg);
    transform-origin: top left;
  }
  
  .package-card {
    background: white;
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
    height: 100%;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  
  .package-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
  }
  
  .package-header {
    margin-bottom: 20px;
    position: relative;
  }
  
  .package-header h3 {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 10px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .package-stars {
    display: flex;
    gap: 5px;
    color: #E94057;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .recommended-badge {
    position: absolute;
    top: -20px;
    right: -15px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 100%);
    color: white;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: 0 5px 15px rgba(233, 64, 87, 0.3);
  }
  
  .package-description {
    margin-bottom: 30px;
  }
  
  .package-description p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
  }
  
  .package-features {
    margin-bottom: 30px;
    flex-grow: 1;
  }
  
  .package-features .feature-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0;
    text-align: left;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .package-features .feature-item:last-child {
    border-bottom: none;
  }
  
  .package-features .feature-item:hover {
    transform: none;
  }
  
  .check-icon {
    color: #E94057;
    margin-top: 3px;
    flex-shrink: 0;
  }
  
  .elite-card {
    border-top: 5px solid #E94057;
  }
  
  .cozy-card {
    border-top: 5px solid #8A2387;
  }
  
  .package-button {
    background: linear-gradient(135deg, #E94057 0%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.2);
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }
  
  .package-button.premium {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 100%);
    box-shadow: 0 8px 20px rgba(138, 35, 135, 0.2);
  }
  
  .package-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.3);
  }
  
  .package-button.premium:hover {
    box-shadow: 0 12px 25px rgba(138, 35, 135, 0.3);
  }
  
  /* CTA Section */
  .cta-section {
    padding: 80px 0;
    background-color: #f5f5f5;
  }
  
  .cta-card {
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    border-radius: 15px;
    padding: 60px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  }
  
  .cta-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #8A2387 0%, #E94057 50%, #F27121 100%);
  }
  
  .cta-title {
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 15px;
  }
  
  .cta-text {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 0;
  }
  
  .cta-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .section-title {
      font-size: 2.2rem;
    }
    
    .cta-card {
      padding: 40px 30px;
    }
    
    .package-card {
      padding: 30px 25px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
    }
    
    .section-title {
      font-size: 1.8rem;
    }
    
    .package-header h3 {
      font-size: 1.8rem;
    }
    
    .cta-button {
      margin-top: 20px;
    }
  }