/* AuthForm.css */
.auth-form-container {
  padding: 10px;
}

.form-section-title {
  font-weight: 700;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.form-description {
  color: #666;
  text-align: center;
  margin-bottom: 24px;
  font-size: 0.95rem;
}

.form-group {
  margin-bottom: 20px;
}

/* Input with icon styling */
.input-icon-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 1rem;
}

.icon-input {
  padding-left: 40px;
  height: 48px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1.5px solid #ddd;
}

.icon-input:focus {
  border-color: #E94057;
  box-shadow: 0 0 0 0.2rem rgba(233, 64, 87, 0.25);
}

/* Auth button styling */
.auth-submit-button {
  background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
  border: none;
  border-radius: 8px;
  height: 48px;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.auth-submit-button:hover, .auth-submit-button:focus {
  background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  box-shadow: 0 4px 12px rgba(233, 64, 87, 0.3);
}

.auth-submit-button:disabled {
  background: linear-gradient(135deg, #c886d3 0%, #f4a0ac 50%, #f9bb92 100%);
}

/* Forgot password link */
.forgot-password-link {
  text-align: right;
  margin-bottom: 20px;
}

.forgot-password-button, .auth-toggle-button {
  color: #E94057;
  font-size: 0.95rem;
  padding: 0;
  text-decoration: none;
}

.forgot-password-button:hover, .auth-toggle-button:hover {
  color: #D93047;
  text-decoration: underline;
}

/* Separator with text */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.separator span {
  padding: 0 10px;
  color: #888;
  font-size: 0.95rem;
}

/* Social login buttons */
.social-buttons {
  display: flex;
  gap: 10px;
}

.social-button {
  flex: 1;
  border-radius: 8px;
  height: 42px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Auth toggle text at bottom */
.auth-toggle {
  text-align: center;
  margin-top: 20px;
  font-size: 0.95rem;
}

.auth-toggle p {
  margin-bottom: 0;
}

/* Modal customization */
.auth-modal .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.auth-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.auth-modal .modal-title {
  font-weight: 700;
  color: #333;
}

.auth-modal .modal-body {
  padding: 20px 24px 30px;
}