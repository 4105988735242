/* Cities.css */
.cities-page {
    overflow-x: hidden;
    margin-top: 80px;
  }
  
  /* Hero Section */
  .cities-hero {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .cities-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* Cities Section */
  .cities-section {
    padding: 100px 0;
    background-color: #f5f5f5;
  }
  
  .cities-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;
    margin-top: 20px;
  }
  
  .city-card-wrapper {
    height: 100%;
  }
  
  .city-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
    height: 100%;
    transition: all 0.3s ease;
  }
  
  .city-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
  
  .city-image-container {
    overflow: hidden;
    height: 200px;
  }
  
  .city-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all 0.5s ease;
  }
  
  .city-card:hover .city-image {
    transform: scale(1.1);
  }
  
  .city-overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .city-overlay h2 {
    color: white;
    font-weight: 800;
    font-size: 2.5rem;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .city-card .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
  }
  
  .city-card .card-text {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .city-details {
    margin-bottom: 20px;
  }
  
  .detail-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
  }
  
  .detail-icon {
    color: #E94057;
    margin-right: 10px;
    margin-top: 3px;
    flex-shrink: 0;
  }
  
  .city-features {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 25px;
  }
  
  .feature-tag {
    display: flex;
    align-items: center;
    background: rgba(233, 64, 87, 0.1);
    color: #E94057;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
  }
  
  .feature-icon {
    margin-right: 5px;
    font-size: 0.8rem;
  }
  
  .city-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .directions-button {
    background: transparent;
    border: 2px solid #E94057;
    color: #E94057;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
    flex-grow: 1;
  }
  
  .directions-button:hover {
    background: rgba(233, 64, 87, 0.1);
    border-color: #E94057;
    color: #E94057;
  }
  
  .appointment-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    color: white;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
  }
  
  .appointment-button:hover {
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(233, 64, 87, 0.2);
  }
  
  /* Contact Section */
  .contact-section {
    padding: 80px 0;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 20px;
  }
  
  .separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    margin: 0 auto 30px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto 30px;
  }
  
  .contact-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
  }
  
  .contact-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .section-title {
      font-size: 2.2rem;
    }
    
    .city-overlay h2 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
    }
    
    .cities-container {
      grid-template-columns: 1fr;
    }
    
    .section-title {
      font-size: 1.8rem;
    }
    
    .city-buttons {
      flex-direction: column;
    }
    
    .directions-button, 
    .appointment-button {
      width: 100%;
    }
  }