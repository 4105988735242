/* Home.css */
.home-page {
    overflow-x: hidden;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
  }
  
  .hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .hero-button {
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
  
  .primary-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
  }
  
  .primary-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  .secondary-button {
    background: transparent;
    border: 2px solid white;
    color: white;
  }
  
  .secondary-button:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: white;
    transform: translateY(-3px);
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* Vision Section */
  .vision-section {
    padding: 80px 0;
    background-color: #f5f5f5;
  }
  
  /* Section Styles */
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: #333;
    position: relative;
  }
  
  .separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    margin: 0 auto 30px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* One Stop Shop Section */
  .one-stop-section {
    padding: 100px 0;
    background-color: white;
  }
  
  .one-stop-content {
    padding-right: 40px;
  }
  
  .one-stop-content .section-title {
    text-align: left;
  }
  
  .one-stop-content .separator {
    margin: 0 0 30px;
  }
  
  .section-text {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 30px;
    line-height: 1.7;
  }
  
  .action-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
  }
  
  .action-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .image-placeholder {
    height: 300px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  .image-placeholder.first {
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.8) 0%, rgba(233, 64, 87, 0.8) 100%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.12'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center;
  }
  
  .image-placeholder.second {
    background: linear-gradient(135deg, rgba(233, 64, 87, 0.8) 0%, rgba(242, 113, 33, 0.8) 100%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath fill-rule='evenodd' d='M0 0h40v40H0V0zm40 40h40v40H40V40zm0-40h2l-2 2V0zm0 4l4-4h2l-6 6V4zm0 4l8-8h2L40 10V8zm0 4L52 0h2L40 14v-2zm0 4L56 0h2L40 18v-2zm0 4L60 0h2L40 22v-2zm0 4L64 0h2L40 26v-2zm0 4L68 0h2L40 30v-2zm0 4L72 0h2L40 34v-2zm0 4L76 0h2L40 38v-2zm0 4L80 0v2L42 40h-2zm4 0L80 4v2L46 40h-2zm4 0L80 8v2L50 40h-2zm4 0l28-28v2L54 40h-2zm4 0l24-24v2L58 40h-2zm4 0l20-20v2L62 40h-2zm4 0l16-16v2L66 40h-2zm4 0l12-12v2L70 40h-2zm4 0l8-8v2l-6 6h-2zm4 0l4-4v2l-2 2h-2z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: cover;
    background-position: center;
    transform: translateY(30px);
  }
  
  .placeholder-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  /* Services Section */
  .services-section {
    padding: 100px 0;
    background-color: #f5f5f5;
    position: relative;
  }
  
  .services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    width: 100%;
  }
  
  .service-card {
    background: white;
    border-radius: 15px;
    padding: 40px 30px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .service-card::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(135deg, #8A2387, #E94057, #F27121, #8A2387);
    background-size: 400% 400%;
    z-index: -1;
    border-radius: 17px;
    animation: gradientBorder 6s ease infinite;
  }
  
  @keyframes gradientBorder {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #E94057;
    transition: all 0.3s ease;
  }
  
  .service-card:hover .service-icon {
    transform: scale(0.8) translateY(-40px);
    opacity: 0.3;
  }
  
  .service-title {
    font-weight: 700;
    font-size: 1.3rem;
    color: #333;
    margin-bottom: 15px;
    transition: all 0.3s ease;
  }
  
  .service-card:hover .service-title {
    transform: translateY(-80px);
  }
  
  .service-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.3s ease;
    border-radius: 15px;
  }
  
  .service-card:hover .service-hover-content {
    opacity: 1;
    transform: translateY(0);
  }
  
  .service-hover-content p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .service-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 0.9rem;
    color: white;
    transition: all 0.3s ease;
  }
  
  .service-button:hover {
    transform: scale(1.05);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Why Choose Us Section */
  .why-us-section {
    padding: 100px 0;
    background-color: white;
    position: relative;
    overflow: hidden;
  }
  
  .diagonal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.03) 0%, rgba(233, 64, 87, 0.03) 50%, rgba(242, 113, 33, 0.03) 100%);
    transform: skewY(-5deg);
    transform-origin: top left;
  }
  
  .benefits-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    width: 100%;
  }
  
  .benefit-card {
    background: white;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  
  .benefit-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    z-index: 2;
  }
  
  .benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(233, 64, 87, 0.1);
  }
  
  .benefit-number {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 3rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.03);
    line-height: 1;
  }
  
  .benefit-icon {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #E94057;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.1) 0%, rgba(233, 64, 87, 0.1) 50%, rgba(242, 113, 33, 0.1) 100%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .benefit-card:hover .benefit-icon {
    transform: rotate(360deg);
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    color: white;
  }
  
  .benefit-title {
    font-weight: 700;
    font-size: 1.2rem;
    color: #333;
    margin-top: 15px;
  }
  
  /* CTA Section */
  .cta-section {
    padding: 80px 0;
    background-color: #f5f5f5;
  }
  
  .cta-card {
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    border-radius: 15px;
    padding: 60px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  }
  
  .cta-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #8A2387 0%, #E94057 50%, #F27121 100%);
  }
  
  .cta-title {
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 15px;
  }
  
  .cta-text {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 0;
  }
  
  .cta-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Responsive Styles */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.8rem;
    }
    
    .section-title {
      font-size: 2.2rem;
    }
    
    .one-stop-content {
      padding-right: 0;
      margin-bottom: 50px;
    }
    
    .image-placeholder.second {
      transform: translateY(0);
    }
    
    .cta-card {
      padding: 40px 30px;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2.3rem;
    }
    
    .hero-buttons {
      flex-direction: column;
      gap: 15px;
    }
    
    .hero-button {
      width: 100%;
    }
    
    .image-grid {
      grid-template-columns: 1fr;
      gap: 30px;
    }
    
    .image-placeholder {
      height: 250px;
    }
    
    .section-title {
      font-size: 2rem;
    }
    
    .services-container, .benefits-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    
    .cta-button {
      margin-top: 20px;
    }
  }