/* Spaces.css */
.spaces-page {
    overflow-x: hidden;
    margin-top: 80px;
  }
  
  /* Hero Section */
  .spaces-hero {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .spaces-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* Gallery Section */
  .spaces-gallery {
    padding: 80px 0;
    background-color: #f5f5f5;
  }
  
  /* Tabs Navigation */
  .spaces-nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    gap: 8px;
  }
  
  .spaces-nav .nav-item {
    margin: 0 5px;
  }
  
  .spaces-nav .nav-link {
    border: none;
    background: white;
    color: #333;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 30px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .spaces-nav .nav-link:hover {
    background: rgba(233, 64, 87, 0.1);
    color: #E94057;
    transform: translateY(-2px);
  }
  
  .spaces-nav .nav-link.active {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
  }
  
  /* Gallery Grid */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
    margin-top: 30px;
  }
  
  .gallery-item {
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .gallery-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    height: 100%;
  }
  
  .gallery-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(233, 64, 87, 0.2);
  }
  
  .image-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 75%; /* 4:3 aspect ratio */
    background: #f5f5f5;
  }
  
  .gallery-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .gallery-card:hover img {
    transform: scale(1.05);
  }
  
  .image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    color: white;
    padding: 20px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
  }
  
  .gallery-card:hover .image-overlay {
    opacity: 1;
    transform: translateY(0);
  }
  
  .image-overlay h3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .image-overlay p {
    font-size: 0.9rem;
    margin-bottom: 0;
    opacity: 0.9;
  }
  
  /* CTA Section */
  .spaces-cta {
    padding: 80px 0;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    text-align: center;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 20px;
  }
  
  .cta-text {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .cta-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    color: white;
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1.1rem;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Loading spinner */
  .spinner-border {
    color: #E94057;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .spaces-nav {
      overflow-x: auto;
      padding-bottom: 10px;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
    
    .cta-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
    }
    
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }