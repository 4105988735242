/* AdminPanel.css */
.admin-panel {
    margin-bottom: 50px;
  }
  
  .admin-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  }
  
  .admin-card-header {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    color: white;
    padding: 15px 20px;
    border: none;
  }
  
  .admin-card-header h3 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
  }
  
  /* File Upload Styling */
  .custom-file-upload {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 10;
  }
  
  .file-label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    background: #f0f0f0;
    border: 2px dashed #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    color: #666;
  }
  
  .file-input:hover + .file-label,
  .file-input:focus + .file-label {
    border-color: #E94057;
    background-color: rgba(233, 64, 87, 0.05);
  }
  
  .selected-file-preview {
    margin-top: 15px;
    text-align: center;
  }
  
  .image-preview {
    max-width: 300px;
    max-height: 200px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Upload button */
  .upload-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.2);
    transition: all 0.3s ease;
  }
  
  .upload-button:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.3);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  .upload-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Progress bar */
  .progress {
    height: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
  }
  
  .progress-bar {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border-radius: 5px;
  }
  
  /* Admin gallery */
  .admin-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .admin-gallery-item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .admin-image-container {
    position: relative;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    background: #f5f5f5;
  }
  
  .admin-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  .admin-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .admin-gallery-item:hover .admin-image-overlay {
    opacity: 1;
  }
  
  .admin-image-overlay h4 {
    margin-bottom: 15px;
    font-size: 1.1rem;
    text-align: center;
    padding: 0 10px;
  }
  
  .delete-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border-radius: 30px;
    font-size: 0.9rem;
  }
  
  .delete-btn:hover {
    transform: scale(1.05);
  }
  
  /* Delete modal */
  .delete-preview {
    max-width: 100%;
    max-height: 250px;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
  }
  
  /* Button variants */
  .btn-gradient {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    color: white;
    transition: all 0.3s ease;
  }
  
  .btn-gradient:hover {
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
    color: white;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .admin-gallery {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .admin-gallery {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
    
    .admin-image-overlay h4 {
      font-size: 0.9rem;
    }
    
    .delete-btn {
      padding: 5px 10px;
      font-size: 0.8rem;
    }
  }