/* HowItWorks.css */
.how-it-works-page {
    overflow-x: hidden;
    margin-top: 80px;
  }
  
  /* Hero Section */
  .how-it-works-hero {
    position: relative;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px 100px;
    text-align: center;
    background: linear-gradient(135deg, #1c1c1c 0%, #2d2d2d 100%);
    overflow: hidden;
  }
  
  .how-it-works-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, rgba(138, 35, 135, 0.4) 0%, rgba(233, 64, 87, 0.4) 50%, rgba(242, 113, 33, 0.4) 100%);
    animation: gradientMove 15s infinite alternate;
    z-index: 1;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 2;
  }
  
  .gradient-text {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 5s infinite alternate;
  }
  
  @keyframes textGradient {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
  }
  
  .shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 2;
  }
  
  .shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 80px;
  }
  
  .shape-divider .shape-fill {
    fill: #f5f5f5;
  }
  
  /* Process Section */
  .process-section {
    padding: 100px 0;
    background-color: #f5f5f5;
    position: relative;
  }
  
  .process-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   
    margin: 0 auto;
  }
  
  .step-card {
    background: white;
    border-radius: 15px;
    padding: 40px 30px;
    width: 270px;
    min-height: 350px;
    text-align: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
    position: relative;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  
  .step-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
  }
  
  .step-number {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 100%);
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .step-icon {
    font-size: 3.5rem;
    color: #E94057;
    margin: 15px 0 25px;
    transition: all 0.3s ease;
  }
  
  .step-card:hover .step-icon {
    transform: scale(1.1);
  }
  
  .step-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
  }
  
  .step-description {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.6;
  }
  
  .step-connector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }
  
  .connector-icon {
    color: #E94057;
    font-size: 1.5rem;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(5px);
    }
    60% {
      transform: translateX(3px);
    }
  }
  
  /* Benefits Section */
  .benefits-section {
    padding: 100px 0;
    background-color: white;
    position: relative;
    overflow: hidden;
  }
  
  .diagonal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.03) 0%, rgba(233, 64, 87, 0.03) 50%, rgba(242, 113, 33, 0.03) 100%);
    transform: skewY(-5deg);
    transform-origin: top left;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 20px;
  }
  
  .separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    margin: 0 auto 30px;
    border-radius: 2px;
  }
  
  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .benefit-card {
    background: white;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    height: 100%;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
  }
  
  .benefit-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  
  .benefit-icon-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .benefit-icon-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.1) 0%, rgba(233, 64, 87, 0.1) 100%);
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  
  .benefit-card:hover .benefit-icon-bg {
    transform: scale(1.1);
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.2) 0%, rgba(233, 64, 87, 0.2) 100%);
  }
  
  .benefit-icon {
    font-size: 2.5rem;
    color: #E94057;
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .benefit-card:hover .benefit-icon {
    transform: rotate(360deg);
    transition: all 0.5s ease;
  }
  
  .benefit-card h3 {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
  }
  
  .benefit-card p {
    font-size: 0.95rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* CTA Section */
  .cta-section {
    padding: 80px 0;
    background-color: #f5f5f5;
  }
  
  .cta-card {
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 50%, rgba(242, 113, 33, 0.05) 100%);
    border-radius: 15px;
    padding: 60px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  }
  
  .cta-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: linear-gradient(to bottom, #8A2387 0%, #E94057 50%, #F27121 100%);
  }
  
  .cta-title {
    font-size: 2rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 15px;
  }
  
  .cta-text {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 0;
  }
  
  .cta-button {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 12px 25px;
    border-radius: 30px;
    font-weight: 600;
    color: white;
    box-shadow: 0 8px 20px rgba(233, 64, 87, 0.3);
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 12px 25px rgba(233, 64, 87, 0.4);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-title {
      font-size: 2.5rem;
    }
    
    .cta-card {
      padding: 40px 30px;
    }
    
    .process-steps {
      flex-direction: column;
    }
    
    .step-card {
      width: 100%;
      max-width: 320px;
      margin-bottom: 40px;
    }
    
    .step-connector {
      transform: rotate(90deg);
      margin: 10px 0;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
    }
    
    .section-title {
      font-size: 2rem;
    }
    
    .cta-button {
      margin-top: 20px;
    }
  }