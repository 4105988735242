/* App.css - Global styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  line-height: 1.6;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Add space for the fixed navbar */
main {
  padding-top: 80px;
  flex: 1;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

p {
  margin-bottom: 1rem;
}

/* Button styles */
.btn {
  border-radius: 8px;
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.btn-primary {
  background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
  border: none;
}

.btn-primary:hover, .btn-primary:focus {
  background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  box-shadow: 0 4px 12px rgba(233, 64, 87, 0.3);
  transform: translateY(-2px);
}

.btn-outline-primary {
  border-color: #E94057;
  color: #E94057;
}

.btn-outline-primary:hover {
  background-color: rgba(233, 64, 87, 0.1);
  border-color: #E94057;
  color: #E94057;
}

/* Card styles */
.card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #E94057;
}

/* Toast notification customization */
.Toastify__toast {
  border-radius: 8px;
}

.Toastify__toast-body {
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  .btn {
    padding: 0.4rem 1rem;
  }
}