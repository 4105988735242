/* Dashboard.css */
.dashboard-page {
    padding: 120px 0 60px;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  /* Dashboard loading and error states */
  .dashboard-loading,
  .dashboard-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 30px;
    text-align: center;
  }
  
  .dashboard-loading p,
  .dashboard-error p {
    margin-top: 15px;
    color: #666;
  }
  
  .dashboard-error .btn {
    margin-top: 20px;
  }
  
  /* Dashboard header */
  .dashboard-header {
    margin-bottom: 30px;
  }
  
  .dashboard-header h1 {
    font-size: 2.2rem;
    font-weight: 800;
    color: #333;
    margin-bottom: 10px;
  }
  
  .welcome-text {
    font-size: 1.1rem;
    color: #666;
  }
  
  /* Profile card */
  .profile-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  }
  
  .user-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .user-info {
    text-align: center;
    margin-bottom: 25px;
  }
  
  .user-info h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: #666;
  }
  
  .info-icon {
    color: #E94057;
    margin-right: 10px;
  }
  
  .profile-actions {
    text-align: center;
  }
  
  .edit-profile-btn {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .edit-profile-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(233, 64, 87, 0.2);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Stats card */
  .stats-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  }
  
  .stats-card h4 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .stat-item {
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(138, 35, 135, 0.05) 0%, rgba(233, 64, 87, 0.05) 100%);
  }
  
  .stat-value {
    font-size: 1.8rem;
    font-weight: 700;
    color: #E94057;
    margin-bottom: 5px;
  }
  
  .stat-label {
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Dashboard tabs */
  .dashboard-tabs-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    height: 100%;
  }
  
  .dashboard-nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .dashboard-nav-tabs .nav-link {
    border: none;
    padding: 10px 15px;
    color: #666;
    font-weight: 600;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .dashboard-nav-tabs .nav-link.active {
    color: #E94057;
    border-bottom: 3px solid #E94057;
    background-color: transparent;
  }
  
  .tab-icon {
    margin-right: 8px;
  }
  
  .dashboard-tab-content {
    padding: 20px 0;
  }
  
  .tab-pane-content {
    min-height: 300px;
  }
  
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    text-align: center;
  }
  
  .empty-state p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .empty-state .btn {
    background: linear-gradient(135deg, #8A2387 0%, #E94057 50%, #F27121 100%);
    border: none;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .empty-state .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(233, 64, 87, 0.2);
    background: linear-gradient(135deg, #7A1377 0%, #D93047 50%, #E26111 100%);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .dashboard-page {
      padding: 100px 0 40px;
    }
    
    .dashboard-header h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .dashboard-page {
      padding: 80px 0 30px;
    }
    
    .dashboard-header h1 {
      font-size: 1.8rem;
    }
    
    .dashboard-nav-tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 5px;
    }
    
    .dashboard-nav-tabs .nav-link {
      white-space: nowrap;
    }
    
    .user-info h3 {
      font-size: 1.3rem;
    }
    
    .stats-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .stat-value {
      font-size: 1.5rem;
    }
  }